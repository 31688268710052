export const ComparisonEvents = {
  select: 'select-car',
  hide: 'hide-comparison'
}

export const comparison = {
  inject: ['currentUser'],
  data() {
    return {
      localKey: 'selectedCars'
    }
  },
  methods: {
    compare(e) {
      window.dispatchEvent(
        new CustomEvent(ComparisonEvents.select, {
          detail: e.detail
        })
      )
    },
    hideComparisonBar() {
      window.dispatchEvent(new CustomEvent(ComparisonEvents.hide))
    },
    clearAllSelection() {
      localStorage.removeItem(this.localKey)
    },
    getActiveListings() {
      const listings = JSON.parse(localStorage.getItem(this.localKey) || '[]')
      return listings
    },
    updateSelectionList(newList) {
      localStorage.setItem(this.localKey, JSON.stringify(newList))
    },
    imageUrl(path, size = 600) {
      return path ? `${import.meta.env.VITE_MARKET_PLACE}/image/${size}x0/${path}.webp` : `${import.meta.env.VITE_MARKET_PLACE}/sdk/assets/car-placeholder.webp`
    },
    convertList(listings) {
      if (!listings?.length) return []
      return listings?.map(item => ({
        id: item?.id,
        name: item?.name,
        image: item?.images?.length ? item?.images[0] : null
      }))
    },
    toggleStyle(type) {
      try {
        const listingList = document.querySelector('seez-sdk-search').shadowRoot.querySelector('.listingsList')
        const filterPanel = document.querySelector('seez-sdk-search').shadowRoot.querySelector('.filtersPanel')
        if (!type) {
          listingList.classList.remove('collapsed', 'expanded')
          filterPanel.classList.remove('collapsed', 'expanded')
          return
        }
        const removed = type === 'expanded' ? 'collapsed' : 'expanded'
        const added = type === 'expanded' ? 'expanded' : 'collapsed'
        listingList.classList.add(added)
        listingList.classList.remove(removed)
        filterPanel.classList.add(added)
        filterPanel.classList.remove(removed)
      } catch (error) {
        //
      }
    },
    async cancelClick(order) {
      const decision = await window.seezSdk.showCancelOrderModal(order.id)
      if (decision === 'cancelOrder') {
        await this.queryApi(`mutation {cancelOrder(orderId: ${order.id}) {id} }`)
        // eslint-disable-next-line require-atomic-updates
        window.location = '/me/order?tab=paused'
      }
      if (decision === 'keepOffer') return
    },
    async buyClick(details) {
      const isReserved = details.state !== 'available'
      const isReservedByMe = details?.reservation?.reservedFor?.id?.toString() === this.currentUser?.id?.toString()
      this.loading = true

      if (this.currentUser) {
        this.loading = true
        const { user } = await this.queryApi('{user{activeOrder{id, state, listingId}, orders {id, listingId, state}}}')
        this.loading = false
        const pausedOrder = user.orders?.find(o => o.listingId === details.id.toString() && o.state === 'paused')

        if (details.reservedBy === this.currentUser.id) {
          // const order = user.activeOrder ?? user.orders.find(o => o.listingId === details.id.toString() && o.state === 'confirmed')
          const order = user.activeOrder ?? pausedOrder

          if (order?.state === 'paused') {
            await window.seezSdk.resumeOrder(order.id)
          }
          // eslint-disable-next-line require-atomic-updates
          window.location = `/order/${order.id}`
          return
        } else if (user.activeOrder?.state === 'ongoing' && !isReserved) {
          const decision = await window.seezSdk.confirmCancelOrder(user.activeOrder.listingId, this.$root.cardPlaceholder, true)
          // eslint-disable-next-line require-atomic-updates
          if (decision === 'ResumeCurrent') window.location = `/order/${user.activeOrder.id}`
          if (decision === 'PauseActiveOrder') {
            // eslint-disable-next-line require-atomic-updates
            await window.seezSdk.pauseOrder(user.activeOrder.id)
            // eslint-disable-next-line require-atomic-updates
            window.location = `/start/${details.id}`
          }
          if (decision === 'CancelAndStartNew') {
            this.loading = true
            await this.queryApi(`mutation {cancelOrder(orderId: ${user.activeOrder.id}) {id} }`)
            this.loading = false
            // eslint-disable-next-line require-atomic-updates
            window.location = `/start/${details.id}`
          }
          return
        } else if (user.activeOrder && user.activeOrder?.state !== 'ongoing') {
          // eslint-disable-next-line require-atomic-updates
          const decision = await window.seezSdk.confirmCancelOrder(user.activeOrder.listingId, this.$root.cardPlaceholder)
          // eslint-disable-next-line require-atomic-updates
          if (decision === 'ResumeCurrent') window.location = `/order/${user.activeOrder.id}`
          if (decision === 'CancelAndStartNew') {
            await this.queryApi(`mutation {cancelOrder(orderId: ${user.activeOrder.id}) {id} }`)
            // eslint-disable-next-line require-atomic-updates
            window.location = `/start/${details.id}`
          }
          return
        } else if (!isReserved && pausedOrder) {
          await window.seezSdk.resumeOrder(pausedOrder.id)
          // eslint-disable-next-line require-atomic-updates
          window.location = `/order/${pausedOrder.id}`
        }
      }
      if (isReserved && !isReservedByMe) {
        window.seezSdk.showUnavailableListingModal('/search/', details.id, undefined, '/search/?sort=-created_date')
        this.loading = false
      } else {
        // eslint-disable-next-line require-atomic-updates
        window.location = `/start/${details.id}`
      }
    }
  },
  selectedFields: `{
    id name model { id name family { id name brand { id name }}} bodyType { name } targetSite { name logo brandingHighlightColor }
    variant year currency kilometrage dealership { id name description logoUrl financing { logo }} abs
    images color hp transmission {name} fuelType { id name } registrationDate state reservedBy
    equipment { design comfort safety uncategorized standard optional} referenceNumber acceleration numAirbags numGears numCylinders
    weight loadCapacity maxAttachment numDoors fuelConsumption range description locatedAt { plainAddress }
    typeName leasePrice { value currency type lease { duration downPayment residualValue type audience }}
    wholesaleLeasePrice { value currency type lease { duration downPayment residualValue type audience }}
    retailPrice { value currency type disclaimer } wholesalePrice { value currency type }
    emiPrice { value currency type emi { loanAmount apr disclaimer paymentTerms }}
    videos greenTax batteryConsumption certified interiorMaterial interiorColor rimSize warranty engineSize numSeats
  }`
}
