<template>
  <div class="compareSelection" v-if="visible">
    <div class="max-w-screen-lg mx-auto selectionContainer" v-if="!expanded">
      <div class="compareTitle">
        <CompareIcon v-if="noCar" />
        <span>{{ totalText ? totalText : t.compareUpto }}</span>
      </div>
      <div v-for="(l, i) in 3" :key="i">
        <div class="selection" v-if="noCar">
          <div class="indicator">{{ l }}</div>
          <div class="selectionName">{{ selectedCars[i]?.name ?? t.noSelection }}</div>
        </div>
      </div>
      <div class="buttons">
        <ChevronUpIcon class="chevron" @click="expanded = true" />
        <CloseIcon class="closeIcon" @click="clear" />
      </div>
    </div>
    <div class="max-w-screen-lg mx-auto selectionFullContainer" v-if="expanded">
      <div class="infoSection">
        <div class="infoText">
          <div class="compareTitle">
            <CompareIcon />
            <span>{{ t.compareUpto }}</span>
          </div>
          <p class="chooseAtLeast">{{ t.chooseAtLeast }}</p>
          <div class="buttons">
            <ChevronUpIcon class="chevronDown" @click="expanded = false" />
          </div>
        </div>
        <button @click="navigate" class="compareButton" :disabled="disabledNavigate">{{ t.compareVehicle }}</button>
      </div>
      <div class="selectionFull" v-for="(l, i) in 3" :key="i">
        <div class="image">
          <img v-if="selectedCars[i]?.id" :src="imageUrl(selectedCars[i]?.image)" alt="car-image" />
          <CarIcon v-if="!selectedCars[i]?.image && isSearchPage" />
          <SearchIcon v-if="!selectedCars[i]?.id && !isSearchPage" @click="searchCar" />
          <CloseIcon v-if="selectedCars[i]?.id" class="removeCar" @click="clickRemove(selectedCars[i]?.id)" />
        </div>
        <div class="selection">
          <div class="indicator">{{ l }}</div>
          <div class="selectionNameLimit">{{ selectedCars[i]?.name ?? t.noSelection }}</div>
          <CloseIcon v-if="selectedCars[i]?.id" class="removeCar" @click="clickRemove(selectedCars[i]?.id)" />
        </div>
      </div>
      <div class="buttons">
        <ChevronUpIcon class="chevronDown" @click="expanded = false" />
        <CloseIcon class="closeIcon" @click="clear" />
      </div>
      <div class="mobileButtons">
        <button @click="navigate" :disabled="disabledNavigate">{{ t.compareVehicle }}</button>
        <button @click="clear" class="transparent">{{ t.closeComparison }}</button>
      </div>
    </div>
  </div>
  <ClearCOmparisonModal :is-open="modalVisible" @on-close-modal="modalVisible = false" @on-clear-selection="confirmClear" />
</template>

<script>
import { langMixin } from '@/lang.js'
import ChevronUpIcon from '@/assets/icons/chevronUp.svg'
import CloseIcon from '@/assets/icons/close.svg'
import CompareIcon from '@/components/icons/IconCompare.vue'
import { ComparisonEvents, comparison } from '@/lib/comparisonMixin.js'
import { querierMixin } from '@/lib/querierMixin'
import CarIcon from '@/assets/icons/car.svg'
import SearchIcon from '@/components/icons/IconSearchButton.vue'
import ClearCOmparisonModal from './ClearComparisonModal.vue'

export default {
  components: { ChevronUpIcon, CloseIcon, CompareIcon, CarIcon, ClearCOmparisonModal, SearchIcon },
  mixins: [langMixin('Comparison'), querierMixin, comparison],
  data() {
    return {
      visible: false,
      expanded: false,
      selectedCars: JSON.parse(localStorage.getItem('selectedCars')) || [],
      loading: false,
      modalVisible: false
    }
  },
  computed: {
    totalText() {
      return this.selectedCars?.length ? this.t.comparisonList?.replace('{value}', this.selectedCars.length) : ''
    },
    noCar() {
      return !this.selectedCars.length
    },
    disabledNavigate() {
      return this.selectedCars.length < 2
    },
    isListingPage() {
      return this.$route.name === 'listing'
    },
    isSearchPage() {
      return this.$route.name === 'search'
    }
  },
  watch: {
    expanded(n) {
      this.toggleStyle(n ? 'expanded' : 'collpased')
    }
  },
  mounted() {
    window.addEventListener(ComparisonEvents.select, e => {
      this.handleAddCar(e)
    })
    window.addEventListener(ComparisonEvents.hide, () => {
      this.visible = false
      this.expanded = false
    })
    const selected = this.getActiveListings()
    if (selected.length) {
      this.visible = true
      this.expanded = false
    }
  },
  methods: {
    clear() {
      this.modalVisible = true
    },
    confirmClear() {
      this.expanded = false
      this.selectedCars = []
      this.loading = false
      this.modalVisible = false
      this.visible = false
      this.clearAllSelection()
      this.$root.compareIds = ''
    },
    async handleAddCar(e) {
      this.visible = true
      this.expanded = true
      if (this.loading) return
      if (!e.detail[0]) return
      const listingId = e.detail[0]
      const findIndex = this.selectedCars.findIndex(item => item?.id === listingId)
      if (this.selectedCars.length === 3 && findIndex < 0) return
      if (findIndex >= 0) {
        this.remove(listingId)
        return
      }
      this.loading = true
      try {
        const query = `{ listing(id: ${listingId}) { id name images }}`
        const result = await this.queryApi(query)
        const newItem = {
          id: result.listing?.id,
          name: result.listing?.name,
          image: result.listing?.images?.length ? result.listing?.images[0] : null
        }
        if (newItem.id) {
          const newList = [...this.selectedCars, { ...newItem }]
          this.selectedCars = newList
          this.updateSelectionList(newList)
        }
      } catch (error) {
        //
      } finally {
        this.loading = false
      }
    },
    clickRemove(id) {
      this.remove(id)
      this.$root.updateCompareIds(id)
    },
    remove(id) {
      const newList = this.selectedCars.filter(item => item?.id !== id)
      this.selectedCars = newList
      this.updateSelectionList(newList)
      if (!newList.length) {
        this.visible = false
        this.expanded = false
      }
    },
    navigate() {
      this.$router.push('/compare')
    },
    searchCar() {
      this.$router.push('/search')
    }
  }
}
</script>

<style lang="scss">
.buttons {
  padding-left: 1.125rem;
  display: flex;
  gap: 0.75rem;
  svg {
    cursor: pointer;
    & > rect {
      fill-opacity: 1;
    }
  }
  .chevronDown {
    transform: rotate(180deg);
  }
  @media screen and (max-width: 75rem) {
    .closeIcon {
      display: none;
    }
    svg > rect {
      fill-opacity: 1;
    }
  }
}
.compareTitle {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  span {
    font-size: 1.125rem;
    font-weight: 600;
  }
}
.chooseAtLeast {
  margin-top: 0.313;
}
.compareButton {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  background-color: var(--accent);
  border: none;
  color: white;
  border-radius: 1.75rem;
  width: fit-content;
  cursor: pointer;
  @media screen and (max-width: 75rem) {
    display: none;
  }
}

.compareButton:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.compareSelection {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: var(--neutral-90);
  color: white;
  padding: 1rem;

  .selectionContainer {
    height: 3.875rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    height: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    @media screen and (max-width: 75rem) {
      & > div > .selection {
        display: none;
      }
    }
    @media screen and (max-width: 36rem) {
      gap: 0;
      justify-content: space-between;
      padding: 0.25rem;
    }
  }
  .selectionFullContainer {
    overflow: scroll;
    padding: 0.625rem 0 1.25rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2.813rem;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 75rem) {
      gap: 1.875rem;
      flex-direction: column;
      padding: 0.25rem;
      & > .buttons {
        display: none;
      }
    }
    .infoSection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .infoText {
        .buttons {
          display: none;
        }
      }

      @media screen and (max-width: 75rem) {
        .infoText {
          width: auto;
          display: flex;
          flex-direction: row;
          .compareTitle {
            flex: 1;
          }
          .buttons {
            display: block;
          }
          .chooseAtLeast {
            display: none;
          }
        }
      }
    }
    .selectionFull {
      display: flex;
      flex-direction: column;
      gap: 0.875rem;

      .image {
        position: relative;
        height: 8.188rem;
        width: 13.938rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0.75rem;

        img {
          height: 100%;
          width: 100%;
          border-radius: 0.75rem;
          object-fit: cover;
        }

        svg > g > path {
          fill: var(--accent);
        }

        .removeCar {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          transform: scale(0.6);

          path {
            fill: var(--accent);
          }
        }

        @media screen and (max-width: 75rem) {
          display: none;
        }
      }
    }

    .mobileButtons {
      display: none;
      @media screen and (max-width: 75rem) {
        display: flex;
        flex-direction: row;
        gap: 0.625rem;
        @media screen and (max-width: 36rem) {
          flex-direction: column;
        }
        button {
          flex: 1;
          width: auto;
          padding: 0.625rem 1.25rem;
          border: none;
          color: var(--white);
          background-color: var(--accent);
          border-radius: 1.75rem;
          font-weight: 600;
          font-size: 1rem;
          cursor: pointer;
        }
        button:disabled {
          background-color: var(--neutral-30);
          color: var(--neutral-80);
        }
        button.transparent {
          background-color: transparent;
        }
      }
    }
  }
}

.selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  max-width: 13.938rem;
  .removeCar {
    display: none;
    cursor: pointer;
  }
  @media screen and (max-width: 75rem) {
    max-width: none;
    .removeCar {
      display: block;
    }
  }
  .indicator {
    height: 1.563rem;
    width: 1.563rem;
    border-radius: 100%;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
  }
  .selectionNameLimit {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
