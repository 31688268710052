<template>
  <Teleport to="body">
    <div :class="`empty-modal ${isOpen ? 'opened' : ''}`">
      <div class="container">
        <div class="header">
          <p>{{ t.clearConfirm }}</p>
          <a class="close-button" @click="$emit('onCloseModal')" role="button">
            <IconCrossX />
          </a>
        </div>
        <ClearSelectionIcon />
        <div class="buttonContainer">
          <button id="cancelButton" class="outlined" @click="$emit('onCloseModal')">
            {{ t.cancel }}
          </button>
          <button id="clearButton" @click="$emit('onClearSelection')">
            {{ t.clearSelection }}
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import ClearSelectionIcon from '@/assets/icons/clearSelection.svg'
import IconCrossX from '@/components/icons/IconCrossX.vue'
import { langMixin } from '@/lang.js'

export default {
  components: { ClearSelectionIcon, IconCrossX },
  mixins: [langMixin('Comparison')],
  props: {
    isOpen: { type: Boolean, default: false }
  },
  emits: ['onCloseModal', 'onClearSelection']
}
</script>

<style lang="scss">
@import '@/assets/styles/mixins';
.empty-modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  inset: 0;
  top: 100%;
  opacity: 0;
  transition:
    top 0.25s ease-out,
    opacity 0.25s ease-out;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.875rem;

  @media screen and (max-width: 36rem) {
    padding: 0;
  }

  &.opened {
    top: 0;
    opacity: 1;
    pointer-events: auto;
  }

  .container {
    max-width: 33.375rem;
    background-color: white;
    position: relative;
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    gap: 3.75rem;
    @media screen and (max-width: 36rem) {
      padding: 1.5rem;
      height: 100%;
      border-radius: 0;
    }

    .header {
      display: flex;
      flex-direction: row;
      margin-bottom: 2.5rem;
      gap: 0.625rem;
      p {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: left;
        text-decoration: underline;
        text-decoration-color: var(--accent);
        flex: 1;
        @media screen and (max-width: 36rem) {
          text-decoration: none;
          border-bottom: 2px solid var(--accent);
        }
      }
    }

    .buttonContainer {
      display: flex;
      gap: 1.25rem;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;

      button {
        padding: 0.75rem 0;
        border-radius: 1.75rem;
        background-color: var(--accent);
        border: none;
        color: var(--white);
        font-size: 1.125rem;
        font-weight: 700;
        max-width: 16.063rem;
        min-width: 12rem;
        width: auto;
        flex: 1;
        white-space: nowrap;
        cursor: pointer;

        @media screen and (max-width: 36rem) {
          max-width: unset;
        }
      }
      button.outlined {
        border: 2px solid var(--accent);
        background-color: transparent;
        color: var(--accent);
      }
    }

    h2 {
      margin: 1.25rem 0 0.625rem 0;
    }

    .close-button {
      background-color: var(--neutral-30);
      border-radius: 100%;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}
</style>
