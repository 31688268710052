<template>
  <svg width="74" height="43" viewBox="0 0 74 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="71" height="40" rx="20" stroke="#CC0605" stroke-width="2" />
    <mask id="mask0_1992_39529" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="24" y="9" width="26" height="25">
      <rect x="24.5" y="9" width="25" height="25" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1992_39529)">
      <path d="M44.9167 30.875L38.3542 24.3125C37.8333 24.7292 37.2344 25.059 36.5573 25.3021C35.8802 25.5451 35.1597 25.6667 34.3958 25.6667C32.5035 25.6667 30.9019 25.0113 29.5911 23.7005C28.2804 22.3898 27.625 20.7882 27.625 18.8958C27.625 17.0035 28.2804 15.4019 29.5911 14.0911C30.9019 12.7804 32.5035 12.125 34.3958 12.125C36.2882 12.125 37.8898 12.7804 39.2005 14.0911C40.5113 15.4019 41.1667 17.0035 41.1667 18.8958C41.1667 19.6597 41.0451 20.3802 40.8021 21.0573C40.559 21.7344 40.2292 22.3333 39.8125 22.8542L46.375 29.4167L44.9167 30.875ZM34.3958 23.5833C35.6979 23.5833 36.8047 23.1276 37.7161 22.2161C38.6276 21.3047 39.0833 20.1979 39.0833 18.8958C39.0833 17.5938 38.6276 16.487 37.7161 15.5755C36.8047 14.6641 35.6979 14.2083 34.3958 14.2083C33.0938 14.2083 31.987 14.6641 31.0755 15.5755C30.1641 16.487 29.7083 17.5938 29.7083 18.8958C29.7083 20.1979 30.1641 21.3047 31.0755 22.2161C31.987 23.1276 33.0938 23.5833 34.3958 23.5833Z" fill="#CC0605" />
    </g>
  </svg>
</template>
